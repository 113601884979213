<template>
  <div style="width: 100%" class="fill-height">
    <v-container>
      <v-row style="margin-top: 40px" class="d-flex justify-center">
        <v-col cols="12" sm="11" lg="11" :class="['px-0']">
          <v-card v-for="(item, i) in sortedCards" :key="i" hover tabindex="0">
            <v-card-text
              height="44"
              :class="[
                item.active === true ? 'status-ok-exp' : 'status-failed-exp',
              ]"
            >
              <v-row>
                <v-col cols="6" :class="[densityPadding]">
                  <div class="pl-1 caption grey--text">Naziv kartice</div>
                  <div class="pl-1 text-capitalize">{{ item.name }}</div>
                </v-col>
                <v-col cols="2">
                  <div class="pl-1 caption grey--text">Aktivna</div>
                  <div class="pl-1 text-capitalize">
                    {{ item.active === true ? "DA" : "NE" }}
                  </div>
                </v-col>
                <v-col cols="4" :class="[densityPadding]">
                  <div class="d-flex align-end justify-end">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mt-0 pt-0"
                          :x-small="density === 'greater'"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click.stop="openEditCard(item)"
                          @click="openEditCard(item)"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ "Editiraj karticu" }}</span>
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <infinite-loading
        ref="InfiniteLoading"
        @infinite="infiniteHandler"
        spinner="waveDots"
      >
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
      <add-card ref="addCard"></add-card>
      <edit-card ref="editCard"></edit-card>
    </v-container>
  </div>
</template>
<script>
import state from '@/state'
import AddCard from '@/modules/point-of-sale/components/card-configuration/AddCard'
import EditCard from '@/modules/point-of-sale/components/card-configuration/EditCard'
import { df } from '@/plugins/firebase'
import { clone } from '@/plugins/utils'
import InfiniteLoading from 'vue-infinite-loading'
import applicationSettings from '@/mixins/applicationSettings'

export default {
  name: 'CardConfiguration',
  mixins: [applicationSettings],
  components: { AddCard, InfiniteLoading, EditCard },
  data: () => ({
    location: undefined,
    cards: [],
    selectedCard: undefined,
    showPass: false,
    sortedCards: [],
    listeners: []
  }),
  mounted () {
  },
  methods: {
    openAddCard () {
      this.$refs.addCard.open(state.getPointOfSale().id, this.cards)
    },
    openEditCard (card) {
      this.$refs.editCard.open(state.getPointOfSale().id, card, this.cards)
    },
    modifyCards (changedCard) {
      let found = false
      this.cards.forEach((card, key) => {
        if (card.id === changedCard.id) {
          found = true
          this.cards[key] = changedCard
        }
      })

      if (!found) {
        this.cards.push(changedCard)
      }

      this.sortedCards = clone(this.cards)
    },
    infiniteHandler ($state) {
      if (state.getPointOfSale().id !== '') {
        const query = df.doc(`company_cards/${state.getCurrentCompany().id}`)
        const listener = query
          .onSnapshot((doc) => {
            if (
              doc &&
              doc.data() &&
              doc.data().cards &&
            doc.data().cards.length > 0
            ) {
              // this.cards = doc.data().cards
              doc.data().cards.forEach(card => {
                this.modifyCards(card)
              })
            }

            $state.complete()
          })
        this.listeners.push(listener)
      } else {
        $state.complete()
      }
    }
  }
}
</script>
<style scoped>
.myTest >>> .vm--modal {
  top: 100px !important;
}
</style>
