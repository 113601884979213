<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="600"
    style="z-index: 6"
  >
    <v-form ref="addCardForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h3 class="text--secondary">Kartica</h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text v-if="card">
          <v-row class="flex-wrap justify-center">
            <v-col>
              <v-row class="pt-0 mt-2 pb-0 mb-0">
                <v-col cols="8" class="pt-0 mt-0 pb-0 mb-0">
                  <v-text-field
                    class="pt-0 mt-0 pb-0 mb-0"
                    dense
                    outlined
                    label="
                    Ime kartice
                    "
                    v-model="card.name"
                    :rules="[rules.req]"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="1" class="pt-2 mt-0 pb-0 mb-0">
                  <v-checkbox
                    class="pt-0 mt-0 pb-0 mb-0"
                    dense
                    outlined
                    label="
                    Aktivna
                    "
                    v-model="card.active"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            Spremi izmjene
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { df } from '@/plugins/firebase'
import rules from '@/plugins/rules'
import state from '@/state'

export default {
  components: { },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    visible: false,
    location: undefined,
    submitting: false,
    card: undefined,
    cards: [],
    po: undefined,
    rules: {
      req: rules.req(),
      eq: rules.eq
    },
    listeners: []
  }),
  mounted () {
  },
  methods: {
    checkLoginData () {

    },
    open (locationId, card, cards) {
      this.cards = cards
      this.card = card
      this.visible = true
      this.submitting = false
      this.locationId = locationId
    },
    close () {
      this.resetInputs()
    },
    resetInputs () {
      this.term = undefined
      this.card = undefined
      this.visible = false
      this.location = undefined
    },
    async submit () {
      if (!this.card) {
        return
      }
      const docRef = df.doc(`company_cards/${state.getCurrentCompany().id}`)

      this.cards.forEach((c, key) => {
        if (c.id === this.card.id) {
          this.cards[key] = this.card
        }
      })

      try {
        await docRef.set(
          {
            cards: this.cards
          }
        )
      } catch (err) {
        console.error('Error saving pos: ', err)
      }

      this.resetInputs()
    }
  }

}
</script>
